// react
import { useState } from "react";
//3rd party
import { Outlet } from "react-router-dom";

// material ui modules
import Box from "@mui/material/Box";
// Hoc
import AuthHoc from "../hoc/AuthHOC";
// internal components
import NavBar from "./Navbar";
import SideBar from "./SideBar";
// context

//utils and helpers
// import { API_URL } from "../utils/helpers";
export const DRAWER_WIDTH = 300;
export const NAVBAR_HEIGHT = 80;
// Layout Component: Defines the structure of the application
function Layout() {
  const [open, setOpen] = useState(true);

  return (
    <Box sx={{ display: "flex" }} className="Layout-Component">
      <SideBar
        drawerWidth={DRAWER_WIDTH}
        open={open}
        setOpen={setOpen}
        getSidebarCredits={() => {
          // getSidebarCredits();
        }}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { lg: `calc(100% - ${DRAWER_WIDTH}px)` },
          position: "relative",
        }}
        className="Layout-Main-Area"
      >
        <NavBar
          drawerWidth={DRAWER_WIDTH}
          handleDrawerToggle={() => setOpen(!open)}
          height={NAVBAR_HEIGHT}
        />
        <Box
          className="Layout-Main-Area-Body"
          sx={{
            height: `calc(100% - ${NAVBAR_HEIGHT}px)`,
            background: "#f9f9f9",
            pt: "1px",

            px: "56px",
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}

export default AuthHoc(Layout);
