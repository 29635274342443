//Mui
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import Paper from "@mui/material/Paper";
import SearchIcon from "@mui/icons-material/Search";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

//react
import React from "react";
//internal
import DatePick from "../date_picker/DatePick";
import SortBy from "../sort_by/SortBy";

function SearchHeader(props) {
  const [value, setValue] = React.useState(null);
  const [filterBy, setFilterBy] = React.useState("first_name");
  const [sortBy, setSortBy] = React.useState(null);
  const handleChange = (event) => {
    setFilterBy(event.target.value);
  };
  const { setSearchKey } = props;
  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (sortBy !== null) {
        setSearchKey((prev) => {
          return {
            ...prev,
            credit_request_status: sortBy === "ALL" ? null : sortBy,
          };
        });
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [setSearchKey, sortBy]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (value !== null) {
        if (filterBy === "first_name") {
          setSearchKey((prev) => {
            return { ...prev, first_name: value, email: null };
          });
        } else if (filterBy === "email") {
          setSearchKey((prev) => {
            return { ...prev, email: value, first_name: null };
          });
        }
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [value, setSearchKey, filterBy]);

  return (
    <Paper
      sx={{
        width: "100%",
        backgroundColor: "transparent",
        mt: ".4rem",
        ...props.sx,
      }}
      elevation={0}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems={"center"} width="100%">
          <TextField
            placeholder={props.searchBarPlaceholder}
            sx={{
              backgroundColor: "#F6F6F6",

              marginRight: ".2rem",
              minWidth: "30%",
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setValue(e.target.value);
            }}
          ></TextField>
          {/* filter by start */}
          <FormControl sx={{ m: 1, minWidth: "10%" }}>
            <Select
              value={filterBy}
              onChange={handleChange}
              defaultValue="email"
              displayEmpty
              sx={{ height: "53px" }}
            >
              {props.filterByMenu.map((item, index) => {
                return (
                  <MenuItem value={item.value} key={index}>
                    {item.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Box>
            {props.showDateFilter && (
              <DatePick
                setSearchKey={props.setSearchKey}
                sx={{ width: "max-content" }}
              />
            )}
          </Box>
          {/* filter by end */}
          <Box>
            {props.showSortBy && (
              <SortBy
                sx={{
                  p: "13.5px 24px",
                  borderRadius: "4px",
                  ml: "16px",
                  width: "max-content",
                }}
                sortByValues={props.sortByValues}
                setSortBy={setSortBy}
              />
            )}
          </Box>
        </Box>

        <Box>
          {props.disableAddButton ? (
            <></>
          ) : (
            <Button
              variant="contained"
              disableElevation
              sx={{ padding: "12px 24px", backgroundColor: "#2F7EC7" }}
              onClick={props.handleClick}
            >
              {props.buttonTitle}
            </Button>
          )}
        </Box>
      </Stack>
    </Paper>
  );
}

SearchHeader.defaultProps = {
  buttonTitle: "Add",
  handleClick: () => {},
  disableAddButton: false,
  searchBarPlaceholder: "Search",
  setSearchKey: () => {},
  filterByMenu: [],
  sortByValues: [],
  sx: {},
};
export default React.memo(SearchHeader);
