// material ui modules
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import { Typography } from "@mui/material";

// material ui icons
import MenuIcon from "@mui/icons-material/Menu";
import useAuth from "../hooks/useAuth";

// libraries
import { useLocation } from "react-router-dom";

// custom compoents
import DropDown from "./Dropdown";

// utils
// import urlpatterns from "../utils/urls.json";
import { genPageTitle } from "../utils/helpers";

// NavBar Component: Navbar for the application
export default function NavBar({
  drawerWidth,
  handleDrawerToggle,
  height,
  sx,
}) {
  const [auth] = useAuth();
  const location = useLocation();

  return (
    <Paper
      elevation={0}
      sx={{
        width: "100%",
        height: `${height}px`,
        px: "56px",
        pt: "10px",
        background: "#f9f9f9",
        ...sx,
      }}
      className="Navbar-Component"
    >
      <AppBar
        elevation={0}
        sx={{
          backgroundColor: "transparent",
          color: "#022539",
          // height: "90px",
        }}
        position="static"
      >
        <Toolbar
          sx={{
            pt: { xs: 0, sm: 4 },
            pb: { xs: 0, sm: 3 },
            padding: "0 !important",
            width: "100%",
            margin: "auto",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open sidebar"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { xs: "block", lg: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h5"
            fontWeight="700"
            component="div"
            sx={{
              flexGrow: 1,
              marginLeft: { xs: 0 },
              textTransform: "capitalize",
            }}
          >
            {genPageTitle(location.pathname)}
          </Typography>
          <Box component="div" sx={{ marginLeft: "auto" }}>
            <DropDown
              btnTitle={`${auth.user.first_name} ${auth.user.last_name}`}
            />
          </Box>
        </Toolbar>
      </AppBar>
    </Paper>
  );
}
