import React from "react";
////////////MUI//////////////////
import { Stack, Typography, Paper } from "@mui/material";

const getDate = (datetimestring) => {
  if (datetimestring === null) {
    return "-";
  }
  const options = { day: "numeric", month: "short", year: "numeric" };
  const date_ = new Intl.DateTimeFormat("en-GB", options).format(
    new Date(datetimestring)
  );
  return date_;
};

function getValue(row, key) {
  function isValidDate(dateString) {
    if (isNaN(dateString))
      //datestring must be a string.
      return new Date(dateString).toString() !== "Invalid Date";
    return false;
  }

  if (
    row[key].toString() === "null" ||
    row[key] === "" ||
    row[key].toString() === "undefined"
  ) {
    return "-";
  } else if (typeof row[key] === "string" && !isValidDate(row[key])) {
    return row[key];
  } else if (typeof row[key] === "boolean") {
    return row[key].toString();
  } else if (typeof row[key] === "number") {
    return row[key];
  } else if (typeof row[key] === "function") {
    return row[key]();
  } else if (isValidDate(row[key])) {
    return getDate(row[key]);
  }
}

const customRow = (row, index) => {
  for (const mainKey in row[0]) {
    return (
      <Stack
        direction="row"
        alignItems="center"
        spacing={5.5}
        key={index}
        sx={(theme) => {
          return row.length > 1 ? { ...row[1].typographyStyle } : {};
        }}
      >
        <Typography
          fontSize="16px"
          fontWeight="400"
          color="#898989"
          lineHeight="21px"
          className="main-key"
          sx={(theme) => {
            return row.length > 1
              ? row[1].typographyStyle
                ? {
                    minWidth: "100px",
                    ...row[1].typographyStyle.keyStyle,
                  }
                : { minWidth: "100px" }
              : { minWidth: "100px" };
          }}
        >
          {mainKey}
        </Typography>
        <Typography
          fontSize="16px"
          fontWeight="500"
          color="#0F0F0F"
          sx={(theme) => {
            return row.length > 1
              ? row[1].typographyStyle
                ? { ...row[1].typographyStyle.valueStyle }
                : {}
              : {};
          }}
        >
          {getValue(row[0], mainKey)}
        </Typography>
      </Stack>
    );
  }
};

export default function UserInfo({
  title,
  titleStyles,
  tagLine,
  tagLineStyles,
  titleTagLineContainerStyles,
  dataContainerStyles,
  data,
  ...props
}) {
  return (
    <>
      <Paper
        elevation={0}
        sx={{
          width: "100%",
          padding: "32px",
          ...props.sx,
        }}
      >
        <Stack
          direction="column"
          sx={{
            borderBottom: " 1px solid #EAEAEA",
            pb: "24px",
            ...titleTagLineContainerStyles,
          }}
        >
          <Typography
            fontSize="20px"
            fontWeight="500"
            color="#0F0F0F"
            pb="8px"
            sx={{ ...titleStyles }}
          >
            {title}
          </Typography>
          <Typography
            fontSize="14px"
            fontWeight="500"
            color="#898989"
            sx={{ ...tagLineStyles }}
          >
            {tagLine}
          </Typography>
        </Stack>

        <Stack
          direction="column"
          spacing={2}
          sx={{ my: "24px", ...dataContainerStyles }}
        >
          {data && data.length ? (
            data.map((row, index) => {
              return customRow(row, index);
            })
          ) : (
            <></>
          )}
        </Stack>
      </Paper>
    </>
  );
}

UserInfo.defaultProps = {
  data: [],
  title: "Title",
  tagLine: "Your one or two line info will come here",
};

///////////////////Sample data format it can accept/////////
// Note - spacing will apply to stack
//Array of arrays
// const useData = [
//   [
//     { first: "hello" },
//     {
//       stackStyle: {
//         border: "1px solid black",
//       },
//       typographyStyle: {
//         keyStyle: { fontSize: "2rem" },
//         valueStyle: { color: "red" },
//       },
//     },
//   ],
//   [{ last: "world",  }],
//   [
//     {
//       full: () => {
//         return "hello world";
//       },
//     },
//   ],
//   [{ isActive: true }],
//   [{ etc: 10 }],
//   [{ date: new Date().toString() }],
//   [{ adfadf: "akjsdlkfajsdlkfjalkdsf" }],
// ];
