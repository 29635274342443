import { useContext } from "react";
import axios from "axios";
//helpers and utils
import { HandleAuthToken, DEBUG } from "../utils/helpers";
//authcontext
import { getNewToken } from "../context/authContext";
import { globalContext } from "../context/globalContext";

// export const axiosInstance = axios.create();

async function getNewAccessToken(dispatch, globalCtx) {
  const handleAuth = new HandleAuthToken();
  const refreshToken = handleAuth.retrieveRefreshToken();

  //for development env
  if (DEBUG) {
    if (refreshToken) {
      const { accessToken, error } = await getNewToken(refreshToken);
      if (!Boolean(error)) {
        dispatch({ type: "update_token", payload: accessToken });
      } else {
        dispatch({ type: "signout", error: null, globalCtx: globalCtx });
        return;
      }
    } else {
      //refresh token not found
      dispatch({ type: "signout", error: null, globalCtx: globalCtx });
      return;
    }
    return;
  }
  //for production env where the refresh token will be sent to backend via cookies
  if (refreshToken) {
    const { accessToken, error } = await getNewToken(refreshToken);
    if (!Boolean(error)) {
      handleAuth.addTokenExp(accessToken.expiration);
    } else {
      dispatch({ type: "signout", error: null, globalCtx: globalCtx });
    }
  }
}

export function useAxios(config) {
  const axiosInstance = axios.create();
  const globalCtx = useContext(globalContext);
  const { dispatch, setIsGettingToken, abortController } = globalCtx;
  // for handling the auth tokens
  const handleAuth = new HandleAuthToken();

  //   request interceptors
  axiosInstance.interceptors.request.use(
    async function (config) {
      //checking the token exp of access token
      if (new Date(handleAuth.retrieveTokenExp()) < new Date()) {
        //checking exp of refresh token
        if (new Date(handleAuth.retrieveRefreshTokenExp()) < new Date()) {
          //refresh token expired
          dispatch({ type: "signout", error: null, globalCtx: globalCtx });
          config["signal"] = abortController.signal;
          return config;
        } else {
          //refresh token not expired get new access token
          setIsGettingToken(true);

          await getNewAccessToken(dispatch, globalCtx);
          setIsGettingToken(false);
        }
      }
      //adding authtoken from localstorage for dev environment and for prod withCreds.
      if (DEBUG) {
        const accessToken = new HandleAuthToken().retrieveToken();
        if (accessToken)
          config["headers"]["Authorization"] = `Bearer ${accessToken}`;
        config["withCredentials"] = false;
      } else {
        config["withCredentials"] = true;
      }
      //adding signal to every req if not contains
      if (!config?.signal) config["signal"] = abortController.signal;

      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  //response interceptors

  //this will handle a edge case if the token is tampered somehow or if network is very slow and token expires somehow and backend return the 401 error then it will get the new token.
  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async function (error) {
      const prevRequest = error?.config;
      if (error?.response?.status === 401 && !prevRequest?.sent) {
        prevRequest.sent = true;
        //getting new token
        setIsGettingToken(true);
        await getNewAccessToken(dispatch, globalCtx);
        setIsGettingToken(false);
        //for dev environment
        if (DEBUG) {
          prevRequest.headers[
            "Authorization"
          ] = `Bearer ${handleAuth.retrieveToken()}`;
          return axiosInstance(prevRequest);
        }
        //for prod
        prevRequest.withCredentials = true;
        return axiosInstance(prevRequest);
      }
      // Do something with request error
      return Promise.reject(error);
    }
  );

  return axiosInstance;
}

export default useAxios;
