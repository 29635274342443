//3rd party
import { useSnackbar } from "notistack";
//react
import { useState, useRef } from "react";

// custom components
import UpdateProfileForm from "../components/forms/updateProfileForm";
import AuthHOC from "../hoc/AuthHOC";
//hooks
import useAxios from "../hooks/useAxios";
// utils
import {
  axiosConfig,
  httpErrorHandler,
  notificationsHandler,
} from "../utils/helpers";
//internal

function Profile({ user, dispatch }) {
  //axios instance
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [, setChangingPass] = useState(false);
  // const [error, setError] = useState(null);

  const updateProfile = async (firstName, lastName, email) => {
    setLoading(true);
    try {
      const config = axiosConfig({
        method: "PATCH",
        uri: `/accounts/manager/`,
        data: { first_name: firstName, last_name: lastName },

        params: { operation: "update-profile" },
      });
      const response = await axiosInstance.current({
        ...config,
      });

      dispatch({ type: "update_user", user: response.data });
      notificationsHandler(
        "success",
        "Profile Updated Successfully",

        enqueueSnackbar,
        closeSnackbar
      );
      setLoading(false);
    } catch (error) {
      if (error && !error.message === "canceled") setLoading(false);
      httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
    }
  };

  const updatePassword = async (password1, password2, old_password) => {
    setChangingPass(true);
    try {
      const config = axiosConfig({
        method: "POST",
        uri: `/auth/password/change/`,
        data: {
          new_password1: password1,
          new_password2: password2,
          old_password,
        },
      });
      await axiosInstance.current({
        ...config,
      });
      notificationsHandler(
        "success",
        "Password changed successfully",
        enqueueSnackbar,
        closeSnackbar
      );
      setChangingPass(false);
    } catch (error) {
      if (error && !error.message === "canceled") setChangingPass(false);
      httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
    }
  };

  return (
    <>
      <UpdateProfileForm
        firstName={user?.first_name}
        lastName={user?.last_name}
        email={user?.email}
        loading={loading}
        updateProfile={updateProfile}
        updatePassword={updatePassword}
        created_on={user?.date_joined}
      />
    </>
  );
}

export default AuthHOC(Profile);
