////////////////MUI//////////////////////////
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import ReplayIcon from "@mui/icons-material/Replay";
///////////////////////Internal Components///////////////////

export default function ComponentLoader(props) {
  return (
    <Paper
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        minHeight: props.minHeight ? props.minHeight : "250px",
        backgroundColor: props.transparent ? "transparent" : "#fff",
        ...props.sx,
      }}
      elevation={0}
    >
      {props.error && props.loading === false ? (
        <IconButton
          aria-label="retry"
          onClick={props.retry ? props.retry : () => {}}
          sx={{ color: props.theme === "dark" ? "#000" : "#fff" }}
        >
          <ReplayIcon />
        </IconButton>
      ) : (
        <>
          <CircularProgress
            sx={{
              mb: "1rem",
              color: props.theme === "dark" ? "#0f0f0f" : "#ffffff",
            }}
            disableShrink
          />
          <Typography
            sx={{ color: props.theme === "dark" ? "#0f0f0f" : "#ffffff" }}
          >
            {props.message ? props.message : ""}
          </Typography>
        </>
      )}
    </Paper>
  );
}

ComponentLoader.defaultProps = {
  error: false,
  loading: false,
  message: "Loading",
  retry: () => {},
  transparent: false,
  theme: "dark",
};
