//react
import { useState } from "react";
//internals
import ResetPasswordForm from "../components/forms/resetPasswordForm";
import PasswordResetSuccessfull from "../components/reset_done/PasswordResetSuccessfull";
//hooks
import useAuth from "../hooks/useAuth";
//utils
import {
  axiosConfig,
  httpErrorHandler,
  notificationsHandler,
} from "../utils/helpers";
import urlpatterns from "../utils/urls.json";
//3rd party
import axios from "axios";
import { Navigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

function ResetPassword() {
  const [auth] = useAuth();
  const { id, token } = useParams();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isResetDone, setIsResetDone] = useState(false);
  const handleSubmit = async (new_password1, new_password2) => {
    setLoading(true);
    try {
      const config = axiosConfig({
        method: "POST",
        uri: `/auth/password/reset/confirm/`,
        data: { uid: id, token, new_password1, new_password2 },
      });
      await axios({
        ...config,
      });
      notificationsHandler(
        "success",
        "Your password has been changed. You can login now",
        enqueueSnackbar,
        closeSnackbar
      );
      setIsResetDone(true);
      setLoading(false);
    } catch (error) {
      if (error && error.message !== "canceled") setLoading(false);
      httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
    }
  };

  if (auth.authenticated) {
    return <Navigate to={urlpatterns.dashboard} />;
  }

  return (
    <>
      {isResetDone ? (
        <PasswordResetSuccessfull />
      ) : (
        <ResetPasswordForm loading={loading} handleSubmit={handleSubmit} />
      )}
    </>
  );
}

export default ResetPassword;
