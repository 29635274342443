//mui
import { Stack, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";

function Activity(props) {
  return (
    <Paper elevation={0} sx={{ height: "100%", background: "#f9f9f9" }}>
      <Stack alignItems="center" justifyContent="center" height="100%">
        <Typography component="h4" fontWeight={700} fontSize="28px">
          Coming Soon
        </Typography>
      </Stack>
    </Paper>
  );
}

export default Activity;
