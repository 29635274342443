import { createContext, useReducer, useState } from "react";
import { HandleAuthToken, DEBUG, logout } from "../utils/helpers";
export const globalContext = createContext();

function signInUser({ user, accessToken = null, refreshToken = null }) {
  const authToken = new HandleAuthToken();
  authToken.addData(user, accessToken, refreshToken);
  return { ...user };
}

async function signOutUser(globalCtx) {
  const authToken = new HandleAuthToken();

  // construct logout request body for debug and prod env
  await logout({
    refreshToken: authToken.retrieveRefreshToken(),
    abortController: globalCtx.abortController,
  });
  authToken.clearData();
  return null;
}

function updateUser(user) {
  const authToken = new HandleAuthToken();
  authToken.addUser(user);
  return { ...user };
}

function updateToken({ token, expiration }) {
  const authToken = new HandleAuthToken();
  if (DEBUG) authToken.addToken(token);
  authToken.addTokenExp(expiration);
}
function updateRefreshToken({ token, expiration }) {
  const authToken = new HandleAuthToken();
  if (DEBUG) authToken.addToken(token);
  authToken.addTokenExp(expiration);
}

const initialState = {
  authenticated: false,
  user: undefined,
  loading: true,
  error: null,
  autoLogoutIntervalId: undefined,
};
function reducer(state, action) {
  switch (action.type) {
    case "signin":
      return {
        authenticated: true,
        loading: false,
        user: signInUser({ ...action.payload }),
        error: action.error,
      };
    case "signout":
      return {
        authenticated: false,
        loading: false,
        user: signOutUser(action.globalCtx),
        error: action.error,
      };
    case "update_user":
      return {
        ...state,
        user: updateUser(action.user),
      };
    case "app_loaded":
      return {
        authenticated: action.authenticated,
        loading: false,
        error: action.error,
        user: action.user,
      };
    case "auto_logout_interval_id":
      return {
        ...state,
        autoLogoutIntervalId: action.autoLogoutIntervalId,
      };
    case "update_token":
      updateToken({ ...action.payload });
      return { ...state };
    case "update_refresh_token":
      updateRefreshToken({ ...action.payload });
      return { ...state };
    default:
      return state;
  }
}
export function GlobalContextProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const abortController = new AbortController();
  const [isGettingToken, setIsGettingToken] = useState(false);
  return (
    <globalContext.Provider
      value={{
        state,
        dispatch,
        isGettingToken,
        setIsGettingToken,
        abortController,
      }}
    >
      {props.children}
    </globalContext.Provider>
  );
}
