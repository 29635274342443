//react
import { useState, memo } from "react";
//mui

import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Status from "../status/Status";

function ClickAwayAccordion(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { openEditPopUp, data } = props;
  const handleClickAway = () => {
    setIsOpen(false);
  };
  const handleAction = () => {
    setIsOpen((prev) => {
      return true;
    });
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Accordion
        elevation={0}
        sx={{
          background: "#F9F9F9",
          p: "20px",
          borderRadius: "8px",
          mb: "24px",
          cursor: "default",
          ".MuiAccordionSummary-root": {
            cursor: "default",
          },
          "::before": {
            opacity: 0,
          },
        }}
        expanded={isOpen}
      >
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          <Grid container>
            <Grid item xs={3}>
              <>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "18px",
                    color: "#6D6D6D",
                  }}
                >
                  Service
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "18px",
                    lineHeight: "23px",
                    color: "#0F0F0F",
                  }}
                >
                  {data?.service?.name ? data?.service?.name.toUpperCase() : ""}
                </Typography>
              </>
            </Grid>
            <Grid item xs={2}>
              <>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "18px",

                    color: "#6D6D6D",
                  }}
                >
                  Credits per Test
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "18px",
                    lineHeight: "23px",

                    color: "#0F0F0F",
                  }}
                >
                  {data?.price ? data?.price : ""}
                </Typography>
              </>
            </Grid>
            <Grid item xs={7}>
              <Box sx={{ display: "flex", float: "right" }}>
                {isOpen ? (
                  <ModeEditOutlineOutlinedIcon
                    fontSize="small"
                    sx={{ color: "#2f7ec7", mr: "5px" }}
                  />
                ) : (
                  <></>
                )}
                <Typography
                  onClick={(e) => {
                    if (!isOpen) {
                      handleAction(setIsOpen);
                    } else {
                      openEditPopUp(e, data);
                    }
                  }}
                  sx={{
                    display: "inline-block",
                    float: "right",
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "21px",

                    color: "#2f7ec7",
                    ":hover": {
                      color: "#215586",
                    },
                  }}
                >
                  {isOpen ? "Edit Serivce" : "Show More"}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ mb: "24px" }}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "18px",

                color: "#6D6D6D",
              }}
            >
              Service Description
            </Typography>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "21px",

                color: "#0F0F0F",
              }}
            >
              {data?.service?.description ? data?.service?.description : ""}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "18px",

                color: "#6D6D6D",
                mb: "1rem",
              }}
            >
              Status
            </Typography>
            {/* <Box sx={{ width: "max-content" }}></Box> */}
            <Status
              status={data?.service?.is_active ? "Active" : "Not Active"}
              sx={{
                px: "1.5rem",
                py: "0.3rem",
                height: "30px",
                display: "inline",
              }}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    </ClickAwayListener>
  );
}

ClickAwayAccordion.defaultProps = {
  openEditPopUp: () => {},
  data: [],
};
export default memo(ClickAwayAccordion);
