//mui
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
//react
import React from "react";
import { Typography } from "@mui/material";

const CustomNotification = React.forwardRef((props, ref) => {
  return (
    <Box sx={{ width: "554px", height: "auto" }} ref={ref}>
      <Alert
        severity={props.variant}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              props.close(props.id);
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{ p: "8px 24px", wordBreak: "break-word" }}
      >
        <AlertTitle>{props.title}</AlertTitle>
        <Typography component="p">{props.message}</Typography>
      </Alert>
    </Box>
  );
});

CustomNotification.defaultProps = {
  title: "This is a notification",
  message: "This is a detailed messaged",
  variant: "success",
};

export default CustomNotification;
