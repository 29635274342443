//mui
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

//main code
export default function Status(props) {
  const status = props.status.toLowerCase();
  const statusColors = {
    cancelled: [
      "Not Started",
      {
        backgroundColor: "#EAEAEA",
        color: "#8A8A8A;",
      },
    ],
    submitted: [
      "Submitted",
      {
        backgroundColor: "#EAEAEA",
        color: "#8A8A8A;",
      },
    ],

    completed: ["Completed", { backgroundColor: "#E0FFDF", color: "#10A44B;" }],

    sent: ["Pending", { backgroundColor: "#FFF3F3", color: "#E03A3A" }],
    accepted: ["Approved", { backgroundColor: "#E9F7FF", color: "#2F7EC7" }],
    rejected: ["Cancelled", { backgroundColor: "#EBEBEB", color: "#959595" }],

    warning: [
      "warning",
      {
        backgroundColor: "#FFFBF1",
        color: "#DB9D64;",
      },
    ],
    ////////additional  ////////
    active: ["Active", { backgroundColor: "#E0FFDF", color: "#10A44B;" }],
    expired: [
      "Expired",
      {
        backgroundColor: "#EAEAEA",
        color: "#8A8A8A;",
      },
    ],
    inactive: [
      "Not Active",
      {
        backgroundColor: "#FFF3F3",
        color: "#E03A3A",
      },
    ],

    default: [
      `${status.charAt(0).toUpperCase() + status.slice(1)}`,
      {
        backgroundColor: "#EAEAEA",
        color: "#8A8A8A;",
      },
    ],
  };
  Status.defaultProps = {
    status: "Status",
  };

  let statusInfo = [];
  if (status in statusColors) {
    statusInfo = statusColors[status];
  } else {
    statusInfo = statusColors["default"];
  }
  return (
    <Box
      sx={{
        height: "30px",
        ...statusInfo[1],
        borderRadius: "5px",
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        minWidth: "130px",
        maxWidth: "145px",
        p: "8px 24px",
        ...props.sx,
      }}
    >
      <Typography component="span">{statusInfo[0]}</Typography>
    </Box>
  );
}
