//react
import { useState, useRef, useEffect, useContext } from "react";
//utils
import {
  axiosConfig,
  httpErrorHandler,
  notificationsHandler,
} from "../utils/helpers";
import urlpatterns from "../utils/urls.json";
import SigninForm from "../components/forms/signinForm";
//hooks
import useAxios from "../hooks/useAxios";
import useAuth from "../hooks/useAuth";
//3rd party
import { Navigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import axios from "axios";
//context
import { globalContext } from "../context/globalContext";

export default function SignIn() {
  const [auth, dispatch] = useAuth();
  //axios instance
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const globalCtx = useContext(globalContext);

  const handleSubmit = async (email, password) => {
    setLoading(true);
    try {
      const config = axiosConfig({
        uri: `/auth/login/`,
        method: "POST",
        data: { email, password },
      });
      const response = await axios({
        ...config,
        withCredentials: true,
      });
      setLoading(false);
      return response;
    } catch (error) {
      if (error && !error.message === "canceled") setLoading(false);
      httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
    }
  };

  useEffect(() => {
    if (auth.authenticated && auth?.user?.type !== "MANAGER") {
      dispatch({ type: "signout", error: null, globalCtx: globalCtx });
      notificationsHandler(
        "error",
        "Please login with correct platform credentials",
        enqueueSnackbar,

        closeSnackbar
      );
    }
  }, [
    auth.authenticated,
    auth?.user?.type,
    dispatch,
    globalCtx,
    enqueueSnackbar,
    closeSnackbar,
  ]);

  if (auth.authenticated) {
    return <Navigate to={urlpatterns.dashboard} />;
  }

  return <SigninForm loading={loading} handleSubmit={handleSubmit} />;
}
