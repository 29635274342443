import { Navigate } from "react-router-dom";
import ForgotPasswordForm from "../components/forms/forgotPasswordForm";
import useAuth from "../hooks/useAuth";
import {
  axiosConfig,
  httpErrorHandler,
  notificationsHandler,
} from "../utils/helpers";
import urlpatterns from "../utils/urls.json";

//3rd party
import { useSnackbar } from "notistack";
//hooks
import axios from "axios";

function ForgotPassword() {
  const [auth] = useAuth();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  //axios instance
  const handleSubmit = async (email) => {
    try {
      const config = axiosConfig({
        method: "POST",
        uri: `/auth/password/reset/`,
        data: { email },
      });
      const response = await axios({
        ...config,
      });
      notificationsHandler(
        "success",
        `${response.data.detail}`,
        enqueueSnackbar,
        closeSnackbar
      );
    } catch (error) {
      httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
    }
  };

  if (auth.authenticated) {
    return <Navigate to={urlpatterns.dashboard} />;
  }

  return <ForgotPasswordForm handleSubmit={handleSubmit} />;
}

export default ForgotPassword;
